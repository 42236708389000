import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import Pulmikas from '../../../utils/Pulmikaslogo.jsx';
import {Button} from "reactstrap";

export default class PlayerIndex extends Component {
    
    render(){
        return (
            <div className="app-container">
                <div className="container-fluid scrollable-container" >
                    <Pulmikas/>

                    <div className="row">
                        <div className="col-md">
                            <p className="app-paragraph" style={{marginTop:'1em'}} >
                                Welcome to Pulmikas, select language.
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md">
                            <p className="app-paragraph" style={{marginTop:'1em'}}>
                                Välkommen till Pulmikas, välj ditt språk.
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md">
                            <p className="app-paragraph" style={{marginTop:'1em'}} >
                                Tervetulos Pulmikkaaseen, valitse kieli.
                            </p>
                        </div>
                    </div>

                    <div className="row button-row" style={{marginTop:'12em'}}>
                        <div className="d-flex justify-content-center col-md">
                            <a href="/ee/selection" className="group-button big-button">
                                eesti
                            </a>
                        </div>
                    </div>
                    <div className="row button-row">
                        <div className="d-flex justify-content-center col-md">
                            <a href="/en/selection" className="group-button big-button">
                                english
                            </a>
                        </div>
                    </div>
                    <div className="row button-row">
                        <div className="d-flex justify-content-center col-md">
                            <a href="/eu/selection" className="group-button big-button">
                                basque
                            </a>
                        </div>
                    </div>
                    <div className="row button-row">
                        <div className="d-flex justify-content-center col-md">
                            <a href="/cs/selection" className="group-button big-button">
                                čeština
                            </a>
                        </div>
                    </div>
                     <div className="row button-row"  >
                        <div className="d-flex justify-content-center col-md">
                            <a href="/cy/selection" className="group-button big-button">
                                Cymraeg
                            </a>
                        </div>
                    </div>
                    <div className="row button-row">
                        <div className="d-flex justify-content-center col-md">
                            <a href="/kk/selection" className="group-button big-button">
                                қазақ
                            </a>
                        </div>
                    </div>
                    <div className="row button-row"  >
                        <div className="d-flex justify-content-center col-md">
                            <a href="/nl/selection" className="group-button big-button">
                                Nederlands
                            </a>
                        </div>
                    </div>
                    <div className="row button-row">
                        <div className="d-flex justify-content-center col-md">
                            <a href="/ru/selection" className="group-button big-button">
                                Русский
                            </a>
                        </div>
                    </div>
                    <div className="row button-row">
                        <div className="d-flex justify-content-center col-md">
                            <a href="/ru-kz/selection" className="group-button big-button">
                                Pусский (Казахстан)
                            </a>
                        </div>
                    </div>
                    <div className="row button-row">
                        <div className="d-flex justify-content-center col-md">
                            <a href="/se/selection" className="group-button big-button">
                                svenska
                            </a>
                        </div>
                    </div>
                    <div className="row button-row"  >
                        <div className="d-flex justify-content-center col-md">
                            <a href="/selection" className="group-button big-button">
                                suomi
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            );
        }
}