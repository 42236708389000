export const KAZAKH_STATE = {
    lang: 'kk',
    messages: {
        "app.gameselection.title": "Pulmikas ойыны",
        "app.gameselection.gameid": "Ойын ID",
        "app.gameselection.instruction": "Ортақ экранда көрсетілген ID-ді енгізіңіз",
        "app.login.title": "Кіру",
        "app.login.logout": "Шығу",
        "app.login.wrongcredentials": "Қате ID немесе құпиясөз",
        "app.login.password": "құпиясөз",
        "app.login.username": "ID",
        "player.groupselection.title": "Команданы таңдаңыз",
        "player.drawavatar.title": "Сурет салу",
        "player.drawavatar.instruction": "Өзіңіздің аватарыңыздың (бейін суреті) суретін салыңыз",
        "player.namegroup.title": "Команданың атауы",
        "player.namegroup.instruction": "Команданың атауын бірге ойластырыңыздар",
        "player.factquestion.answer.title": "Жауапты енгізу",
        "facilitator.factquestion.answer": "Барлық ойыншылар жауап бергенше күтіңіз",
        "facilitator.factquestion.result": "Ойыншылар дұрыс жауапты тексеріп жатыр",
        "player.openquestion.answerscreen.placeholder": "Жауаптың ұзындығы: 100 таңба",
        "screen.openquestion.answerscreen.choosebest": "Төмендегі нұсқалардың ішінен ең дұрысын таңдаңыз",
        "player.openquestion.answerscreen.voteinstruction": "Дұрыс жауапты таңдаңыз",
        "facilitator.openquestion.answerscreen.answer": "Ойыншылар берілген көмектерді талқылап, жауаптарын енгізгенше күтіңіз.",
        "player.ratescreen.title": "Ойын үшін рақмет!",
        "player.ratescreen.instruction": "Ойынға қандай баға берер едіңіз?",
        "facilitator.gameexit.confirmation": "Ойыннан шыққыңыз келетініне сенімдісіз бе?",
        "facilitator.logout.confirmation": "Жүйеден шыққыңыз келетініне сенімдісіз бе?",
        "facilitator.gameselect.title": "Ойынды таңдаңыз",
        "facilitator.groupsize.title": "Қанша команда?",
        "facilitator.groupsize.instruction": "Ұсынылатын команда мүшелерінің саны — 4, Ең көп команда мүшелерінің саны — 6",
        "facilitator.groupmoderation.title": "Жүргізу",
        "facilitator.groupmoderation.instruction": "Командалар дайын",
        "facilitator.groupmoderation.namereset": "Атауын ауыстыру",
        "facilitator.groupmoderation.info": "Ойыншылар оқып жатыр. Сондай-ақ мәтінді дауыстап оқыңыз.",
        "facilitator.waitscreen.read": "Ойыншылар оқып жатыр. Сондай-ақ мәтінді дауыстап оқуға болады.",
        "facilitator.waitscreen.discuss": "Ойыншылар сұрақты талқылап жатыр.",
        "facilitator.waitscreen.multiquestion.discuss": "Ойыншылар сұрақты талқылап жатыр. Әрі қарай жалғастырсаңыз, ойыншылар жауап нұсқалары мен көмектерді алады.",
        "facilitator.waitscreen.openquestion.discuss": "Ойыншылар сұрақты талқылап жатыр. Әрі қарай жалғастырсаңыз, ойыншылар жауапты енгізу өрісі мен көмектерді алады.",
        "facilitator.waitscreen.debatewheel": "Жауапты түсіндіретін ойыншы кездейсоқ таңдалады.",
        "facilitator.waitscreen.voteresults": "Ойыншылар дауыс беру нәтижелерін қарап жатыр.",
        "facilitator.waitscreen.debateresults": "Ойыншылар нәтижелерді қарап жатыр.",
        "facilitator.setupbigscreen.title": "Ортақ экран",
        "facilitator.setupbigscreen.subtitle": "Ойын түрін көрсету үшін келесі парақшаға өтіңіз:",
        "facilitator.setupbigscreen.description": "pulmikas.net/kk/screen",
        "facilitator.setupbigscreen.screencode": "Ойын ID",
        "facilitator.setupbigscreen.instructiontitle": "Көмек",
        "facilitator.setupbigscreen.instructioncontent-1": "Ортақ экран барлық ойыншыға көрінуі керек.",
        "facilitator.setupbigscreen.instructioncontent-2": "Осы құрылғының экранын ортақ пайдалану мүмкін болса, басқа браузер терезесін ашып, pulmikas.net/kk/screen парақшасына өтіңіз.",
        "facilitator.setupbigscreen.instructioncontent-3": "Егер экранмен бөлісу үшін басқа құрылғыны пайдалансаңыз, сол құрылғының браузерінде pulmikas.fi/screen парақшасына өтіп, ашылған терезеге ойын ID енгізіңіз.",
        "facilitator.setupbigscreen.url": "/screen",
        "facilitator.factquestion.answermoderation.answers": "жауаптар",
        "facilitator.multiquestion.answerreset": "Жауапты жою",
        "facilitator.multiquestion.answer": "Жауап:",
        "facilitator.multiquestion.answerscreen.waitingforanswer": "Жауапты күтуде",
        "facilitator.multiquestion.answerscreen.ready": "Дайын",
        "facilitator.multiquestion.debatemoderation.title": "Түсініктеме",
        "facilitator.multiquestion.debatemoderation.speaking": "Сөйлеп жатқан топ:",
        "facilitator.multiquestion.debatemoderation.accepted": "Қабылданды",
        "facilitator.multiquestion.debatemoderation.declined": "Қабылданбады",
        "facilitator.multiquestion.debatemoderation.instruction": "Топтың түсініктемесін тыңдаңыз",
        "facilitator.multiquestion.answer.answer": "Ойыншылар көмектерді талқылап, дұрыс жауапты таңдағанша күте тұрыңыз.",
        "facilitator.multiquestion.answer.result": "Ойыншылар нәтижелерді қарап жатыр.",
        "screen.welcomescreen.title": "Қош келдіңіз",
        "screen.welcomescreen.instruction": "pulmikas.net парақшасына өтіңіз",
        "screen.factquestion.answerScreen.title": "Дұрыс жауап",
        "screen.multiquestion.debatescreen.instruction": "Неліктен таңдаған нұсқаңыз дұрыс деп ойлайсыз, түсіндіріңіз",
        "screen.openquestion.debatescreen.instruction": "Командаңыздың жауабына түсініктеме беріңіз",
        "facilitator.openquestion.vote": "Ойыншылар дұрыс жауапқа дауыс беруде.",
        "screen.debatewheel.title": "Кім түсіндіреді?",
        "thanksforplaying": "Ойын үшін рақмет!",
        "startbutton": "Ойынды таңдаңыз",
        "startgamebutton": "Ойынды бастаңыз",
        "startnewgame": "Жаңа ойынды бастаңыз",
        "logout": "Шығу",
        "readybutton": "Дайын",
        "nextbutton": "Келесі",
        "group": "Команда",
        "wait": "Күтіңіз",
        "yes": "Иә",
        "no": "Жоқ",
        "hint": "Көмек",
        "discuss": "Талқылау",
        "listen": "Тыңдаңыз",
        "debate": "Түсініктеме беріңіз",
        "forward": "Жалғастыру",
        "send": "Жіберу",
        "press1second": "Екі секунд ұстап тұрыңыз",
        "waitMoment": "Біраз күте тұрыңыз",
        "errorMessage": "Бірдеңе дұрыс болмады, жүйеге қайта кіріп көріңіз"
    }
  };