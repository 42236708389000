import React, {Component} from 'react';
import leaveGame from "static/svg/leave-game.svg";
import { FormattedMessage } from 'react-intl';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import confirmationMessage from '../../../utils/confirmationTranslation.ts'

export default class SharedScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sent: false,
            gameId: "",
            show_info: false,
            language: null,
        };
        this.showInfo = this.showInfo.bind(this);
    }

    showInfo(){
        this.setState({show_info:!this.state.show_info})
    }
    
    render(){
        let infoStyle;
        infoStyle = this.state.show_info ? null : {display:'none'};
        return (
            <div className ="app-container">
                <div className="container-fluid facilitator-container  scrollable-container">
                    <div className="info-window" style={infoStyle}>
                        <div className="row">
                            <div className="col-md">
                                <p className="app-info-paragraph" style={{float:'left'}} >
                                    <FormattedMessage id='facilitator.setupbigscreen.instructiontitle'/>
                                </p>
                            </div>
                        </div>
                        <div className="row" style={{paddingTop:'3rem'}}>
                            <div className="col-md">
                                <p className="app-info-paragraph" style={{float:'left'}} >
                                    <FormattedMessage id='facilitator.setupbigscreen.instructioncontent-1'/>
                                    <br/>
                                    <br/>
                                    <FormattedMessage id='facilitator.setupbigscreen.instructioncontent-2'/>
                                    <br/>
                                    <br/>
                                    <FormattedMessage id='facilitator.setupbigscreen.instructioncontent-3'/>
                                </p>
                            </div>
                        </div>
                    </div>
                    <span className="exit-button" onClick={() => this.props.exitGame() }><img style={{width:"100%", height:"100%"}} src={leaveGame}/></span>      
                    <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            <h1 className="app-title" style={{paddingTop:'1em', paddingBottom:'1em'}}><FormattedMessage id='facilitator.setupbigscreen.title'/></h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            <p className="app-paragraph" style={{width:'70%', fontSize:'5em'}}><FormattedMessage id='facilitator.setupbigscreen.subtitle'/></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            <Link
                                target="_blank"
                                to={{
                                pathname: confirmationMessage(this.state.language,'facilitator.setupbigscreen.url'),
                            }}><p className="app-paragraph" style={{width:'100%', marginTop:'1em'}}><FormattedMessage id='facilitator.setupbigscreen.description'/></p></Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            <h1 className="app-title" style={{paddingTop:'2em'}}><FormattedMessage id='facilitator.setupbigscreen.screencode'/> </h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            <input className="game-select" type="text" style={{marginTop:'0.5em'}} value={this.props.information.data.screen_code} disabled />
                        </div>
                    </div>
                    <span className="info-button" onClick={this.showInfo}> ? </span>
                </div>
            </div>
        );
    }
}

SharedScreen.contextTypes = {
  intl: PropTypes.object.isRequired
};
