import React from 'react';
import {Switch, Route } from 'react-router';

import ScreenGameSelection from './game/views/screenviews/GameSelection.jsx';
import CheckLogin from './game/views/facilitatorviews/CheckLogin.jsx';
import AdminCheckLogin from './game/views/facilitatorviews/AdminCheckLogin.jsx';
import GameSelection from './game/views/playerviews/GameSelection.jsx';
import GameApp from './game/GameApp.jsx';
import ScreenGameApp from './game/ScreenGameApp.jsx';
import PlayerIndex from './game/views/playerviews/PlayerIndex.jsx';

const languageOptionsRegex = "lng(se|en|cy|nl|cs|eu|ee|ru|kk|ru-kz)?";

export default (
  <Switch>
    <Route
        key="index"
        name="index"
        exact path="/"
        component={PlayerIndex}
    >
    </Route>
    <Route
        key="selection"
        name="selection"
        exact path={"/:" + languageOptionsRegex + "/selection"}
        component={GameSelection}
    >
    </Route>
    <Route
        key="game"
        name="game"
        path="/game"
        component={GameApp}
    >
    </Route>
    <Route
        key="screen-game"
        name="screen-game"
        path="/screen-game"
        component={ScreenGameApp}
    >
    </Route>
    <Route
        key="screen"
        name="screen"
        path={"/:" + languageOptionsRegex + "/screen"}
        component={ScreenGameSelection}
    >
    </Route>
    <Route
        key="login"
        name="login"
        path={"/:" + languageOptionsRegex + "/login"}
        component={CheckLogin}
    >
    </Route>
    <Route
        key="admin-login"
        name="admin-login"
        path={"/:" + languageOptionsRegex + "/admin-login"}
        component={AdminCheckLogin}
    >
    </Route>
  </Switch>
);
