import {FINNISH_STATE} from 'static/locales/fi.js'
import {SWEDISH_STATE} from 'static/locales/se.js'
import {ENGLISH_STATE} from 'static/locales/en.js'
import {WELSH_STATE} from "static/locales/cy.js";
import {DUTCH_STATE} from "static/locales/nl.js";
import { CZECH_STATE } from "static/locales/cs.js";
import { BASQUE_STATE } from "static/locales/eu.js"
import { ESTONIAN_STATE } from "static/locales/ee.js"
import { RUSSIAN_STATE } from "static/locales/ru.js"
import { KAZAKH_STATE } from "static/locales/kk.js"
import { KZ_RUSSIAN_STATE } from 'static/locales/ru_kz'

const initialState = FINNISH_STATE;
  

/**
 * This is a reducer handling all language changes
 */
  export default function languagesReducer(state = initialState, action) {
    switch (action.type) {
      case 'LANGUAGE_FINNISH':
        return FINNISH_STATE;
  
      case 'LANGUAGE_SWEDISH':
        return SWEDISH_STATE;

      case 'LANGUAGE_ENGLISH':
        return ENGLISH_STATE;

      case 'LANGUAGE_WELSH':
        return WELSH_STATE;

      case 'LANGUAGE_DUTCH':
        return DUTCH_STATE;

      case 'LANGUAGE_CZECH':
        return CZECH_STATE;

      case 'LANGUAGE_BASQUE':
        return BASQUE_STATE;

      case 'LANGUAGE_ESTONIAN':
        return ESTONIAN_STATE;

      case 'LANGUAGE_RUSSIAN':
        return RUSSIAN_STATE;

      case 'LANGUAGE_KAZAKH':
        return KAZAKH_STATE;

      case 'LANGUAGE_KZ_RUSSIAN':
        return KZ_RUSSIAN_STATE

      default:
        return state;
    }
  }
  