export const KZ_RUSSIAN_STATE = {
    lang: 'ru-kz',
    messages: {
        "app.gameselection.title": "Игра Pulmikas",
        "app.gameselection.gameid": "Идентификатор игры",
        "app.gameselection.instruction": "Введите идентификатор, отображаемый на общем экране",
        "app.login.title": "Войти в игру",
        "app.login.logout": "Выйти из игры",
        "app.login.wrongcredentials": "Неверный идентификатор или пароль!",
        "app.login.password": "пароль",
        "app.login.username": "Идентификатор",
        "player.groupselection.title": "Выберите команду",
        "player.drawavatar.title": "Создать",
        "player.drawavatar.instruction": "Нарисуйте аватар команды",
        "player.namegroup.title": "Название команды",
        "player.namegroup.instruction": "Вместе придумайте название команды",
        "player.factquestion.answer.title": "Введите свой ответ",
        "facilitator.factquestion.answer": "Подождите, пока все игроки не ответят.",
        "facilitator.factquestion.result": "Игроки проверяют правильность ответа.",
        "player.openquestion.answerscreen.placeholder": "Максимальная длина: 100 символов",
        "screen.openquestion.answerscreen.choosebest": "Выберите лучший ответ из следующих вариантов",
        "player.openquestion.answerscreen.voteinstruction": "Выберите лучший ответ",
        "facilitator.openquestion.answerscreen.answer": "Подождите, пока игроки обсудят подсказки и введут свой ответ.",
        "player.ratescreen.title": "Спасибо за игру",
        "player.ratescreen.instruction": "Как бы вы оценили эту игру?",
        "facilitator.gameexit.confirmation": "Вы уверены, что хотите покинуть игру?",
        "facilitator.logout.confirmation": "Вы уверены, что хотите выйти из игры?",
        "facilitator.gameselect.title": "Выберите игру",
        "facilitator.groupsize.title": "Количество команд",
        "facilitator.groupsize.instruction": "Рекомендуемое количество игроков в команде – 3-4 человека. Максимальное количество команд – 6",
        "facilitator.groupmoderation.title": "Панель модератора",
        "facilitator.groupmoderation.instruction": "Команды готовы",
        "facilitator.groupmoderation.namereset": "Сбросить имя",
        "facilitator.groupmoderation.info": "Игроки присоединяются к игре. Начните игру, когда все будут готовы.",
        "facilitator.waitscreen.read": "Игроки читают задание. Вы также можете прочитать текст вслух.",
        "facilitator.waitscreen.discuss": "Игроки обсуждают вопрос.",
        "facilitator.waitscreen.multiquestion.discuss": "Игроки в процессе обсуждения. Когда вы продолжите, они получат варианты ответов и подсказки.",
        "facilitator.waitscreen.openquestion.discuss": "Игроки в процессе обсуждения. Когда вы продолжите, у них откроется поле для выбора ответов и подсказки.",
        "facilitator.waitscreen.debatewheel": "Случайным образом выбирается игрок, который должен объяснить ответ команды.",
        "facilitator.waitscreen.voteresults": "Игроки смотрят на результаты голосования.",
        "facilitator.waitscreen.debateresults": "Игроки смотрят на результаты.",
        "facilitator.setupbigscreen.title": "Общий экран",
        "facilitator.setupbigscreen.subtitle": "Откройте общее игровое окно, перейдя по ссылке:",
        "facilitator.setupbigscreen.description": "pulmikas.net/ru-kz/screen",
        "facilitator.setupbigscreen.screencode": "Идентификатор игры",
        "facilitator.setupbigscreen.instructiontitle": "Совет:",
        "facilitator.setupbigscreen.instructioncontent-1": "Общий экран должен быть виден всем игрокам.",
        "facilitator.setupbigscreen.instructioncontent-2": "Если вы используете то же самое устройство для демонстрации общего экрана, что и для входа в игру, откройте новую вкладку в браузере и перейдите по адресу pulmikas.net/screen.",
        "facilitator.setupbigscreen.instructioncontent-3": "Если вы используете другое устройство для демонстрации общего экрана, перейдите по адресу pulmikas.fi/screen, и в открывшемся окне введите идентификатор.",
        "facilitator.setupbigscreen.url": "/screen",
        "facilitator.factquestion.answermoderation.answers": "ответы",
        "facilitator.multiquestion.answerreset": "Удалить ответ",
        "facilitator.multiquestion.answer": "Ответ:",
        "facilitator.multiquestion.answerscreen.waitingforanswer": "Ожидание ответа",
        "facilitator.multiquestion.answerscreen.ready": "Готово",
        "facilitator.multiquestion.debatemoderation.title": "Объяснение",
        "facilitator.multiquestion.debatemoderation.speaking": "Выступающая команда:",
        "facilitator.multiquestion.debatemoderation.accepted": "Принять",
        "facilitator.multiquestion.debatemoderation.declined": "Отклонить",
        "facilitator.multiquestion.debatemoderation.instruction": "Послушайте ответ команды",
        "facilitator.multiquestion.answer.answer": "Подождите, пока игроки обсудят подсказки и выберут ответ.",
        "facilitator.multiquestion.answer.result": "Игроки смотрят на результаты.",
        "screen.welcomescreen.title": "Добро пожаловать",
        "screen.welcomescreen.instruction": "Перейти по адресу pulmikas.net",
        "screen.factquestion.answerScreen.title": "Правильный ответ",
        "screen.multiquestion.debatescreen.instruction": "Пожалуйста, объясните, почему вы считаете, что выбранный вами вариант является наилучшим",
        "screen.openquestion.debatescreen.instruction": "Пожалуйста, объясните ответ вашей команды",
        "facilitator.openquestion.vote": "Игроки голосуют за лучший ответ.",
        "screen.debatewheel.title": "Кто будет отвечать?",
        "thanksforplaying": "Спасибо вам за игру",
        "startbutton": "Выберите игру",
        "startgamebutton": "Начать игру",
        "startnewgame": "Начать новую игру",
        "logout": "Выйти из игры",
        "readybutton": "Завершить",
        "nextbutton": "Далее",
        "group": "Команда",
        "wait": "Подождите",
        "yes": "Да",
        "no": "Нет",
        "hint": "Подсказка",
        "discuss": "Обсудите",
        "listen": "Послушайте",
        "debate": "Объясните",
        "forward": "Далее",
        "send": "Отправить",
        "press1second": "Удерживайте кнопку две секунды",
        "waitMoment": "Подождите",
        "errorMessage": "Что-то пошло не так, пожалуйста, попробуйте еще раз"
    }
  };